import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

const PrintableCode = ({ name }) => {
  const CodeAndName = (name) => {
    // The QRCode library is supposed to pass through styles, but it doesn't seem to work,
    // so all styles are in-line here to keep everything in one place
    return (
      <div style={{ width: '99px', marginBottom: '90px' }}>
        <p
          style={{
            fontFamily: 'Roboto Mono',
            monospace: true,
            fontSize: '19px',
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            margin: '0 0 6px 0',
          }}>
          {name}
        </p>
        <QRCode
          size={92}
          style={{ marginLeft: '4px' }}
          value={`https://razor.mobi/scan/v1/${name}`}
        />
      </div>
    );
  };

  return (
    <div style={{ margin: '4rem 0 0 4rem' }}>
      {CodeAndName(name)}
      <div className="printOnlyCodes">
        {CodeAndName(name)}
        {CodeAndName(name)}
      </div>
    </div>
  );
};

PrintableCode.propTypes = {
  name: PropTypes.string,
};

const RazorSingleCodes = () => {
  const componentRef = useRef();
  const [name, setName] = useState('');

  const onChange = (event) => {
    const value = event.target.value.sanitizeNonAlphaNumerics().toUpperCase();
    const truncatedValue = value.length > 6 ? value.substr(0, 6) : value;
    setName(truncatedValue);
  };

  const onClickReset = (event) => {
    event.preventDefault();
    setName('');
  };

  return (
    <div className="print_qr_code_container">
      <p>
        Enter the QR Code found on the back of the head unit in the field below. Printable QR codes will appear below.{' '}
        <strong>Do not use the browser print function to print the codes!</strong>. Press the
        &lsquo;print&rsquo; button for a printer-friendly version of this page with three copies of
        the QR code.
      </p>
      <form>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="name">
            Vehicle Name
          </label>
          <input type="text" name="name" placeholder="Name" value={name} onChange={onChange} />
        </div>
        <div className="form_input_section">
          <button onClick={onClickReset}>Reset</button>
          <ReactToPrint
            trigger={() => (
              <button type="button" disabled={name.length !== 4}>
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </form>
      <div ref={componentRef}>
        {name.length > 3 && name.length < 7 && <PrintableCode name={name} />}
      </div>
    </div>
  );
};

export default RazorSingleCodes;
