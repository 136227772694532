import React, { useState } from 'react';
import { DropdownList } from 'react-widgets';

import AuthenticatedPage from '../../Components/AuthenticatedPage';
import SingleQRCodes from '../../Components/QRCodes/RazorScooter';
import MultiQRCodes from '../../Components/QRCodes/RazorMultipleScooters';
import OkaiQRCodes from '../../Components/QRCodes/Okai';
import BatteryQRCodes from '../../Components/QRCodes/Batteries';

import './index.scss';

const EditScooter = () => {
  const codeTypes = [
    { id: 'razorScooter', name: 'Razor Single Scooter' },
    { id: 'razorMulti', name: 'Razor Multiple Scooters' },
    { id: 'batteries', name: 'Razor Batteries' },
    { id: 'okai', name: 'Okai Ebike' },
  ];
  const [selectedCode, setSelectedCode] = useState(codeTypes[0]);

  const handleCodeChange = (newCode) => setSelectedCode(newCode);

  return (
    <AuthenticatedPage>
      <div className="page-title">Print QR Code Sheets</div>
      <div className="codePicker">
        <p>QR Code type to print:</p>
        <div className="codePicker__dropdown">
          <DropdownList
            data={codeTypes}
            dataKey="id"
            textField="name"
            value={selectedCode}
            valueField="codeType"
            onChange={handleCodeChange}
          />
        </div>
      </div>
      {selectedCode.id === 'razorScooter' && <SingleQRCodes />}
      {selectedCode.id === 'razorMulti' && <MultiQRCodes />}
      {selectedCode.id === 'batteries' && <BatteryQRCodes />}
      {selectedCode.id === 'okai' && <OkaiQRCodes />}
    </AuthenticatedPage>
  );
};

export default EditScooter;
