import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

const MultiQrCodes = () => {
  const componentRef = useRef();
  const [names, setNames] = useState('');
  const [qrList, setQrList] = useState([]);

  useEffect(() => {
    const namesArr = names
      .toUpperCase()
      .split(',')
      .map((name) => name.sanitizeNonAlphaNumerics())
      .filter((name) => name.length === 4);
    setQrList(namesArr);
  }, [names]);

  const CodeAndName = (name) => {
    // The QRCode library is supposed to pass through styles, but it doesn't seem to work,
    // so all styles are in-line here to keep everything in one place
    return (
      <div style={{ width: '99px', marginBottom: '15px' }}>
        <p
          style={{
            fontFamily: 'Roboto Mono',
            monospace: true,
            fontSize: '19px',
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            margin: '0 0 6px 0',
          }}>
          {name}
        </p>
        <QRCode
          size={92}
          style={{ marginLeft: '4px' }}
          value={`https://razor.mobi/scan/v1/${name}`}
        />
      </div>
    );
  };

  const onChangeNames = (event) => setNames(event.target.value);

  const onClickReset = (event) => {
    event.preventDefault();
    setNames('');
  };

  const getReturn = (name) => {
    return (
      <div key={name} style={{ margin: '2rem 0 0 .95rem', float: 'left' }}>
        {CodeAndName(name)}
        <div className="printOnlyCodes">
          {CodeAndName(name)}
          {CodeAndName(name)}
        </div>
      </div>
    );
  };

  return (
    <div className="print_qr_code_container">
      <p>
        Enter 1 or more QR Codes from your Razor Vehicle to generate printable QR codes. Seperate multiple names with
        commas.
      </p>
      <form>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="names">
            Vehicle Names (comma separated)
          </label>
          <textarea
            type="textarea"
            name="names"
            placeholder="Names"
            value={names}
            onChange={onChangeNames}
          />
        </div>
        <div className="form_input_section">
          <button onClick={onClickReset}>Reset</button>
          <ReactToPrint
            trigger={() => <button type="button">Print</button>}
            content={() => componentRef.current}
          />
        </div>
      </form>
      <div ref={componentRef}>{qrList.map((name) => getReturn(name))}</div>
    </div>
  );
};

export default MultiQrCodes;
