import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import './index.scss';

const PrintableBatteryCode = ({ qrCode = '' }) => {
  const CodeAndName = (name) => {
    // The QRCode library is supposed to pass through styles, but it doesn't seem to work,
    // so all styles are in-line here to keep everything in one place
    return (
      <div
        style={{
          width: '210px',
          marginBottom: '60px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <p
          style={{
            fontWeight: 'bold',
            monospace: true,
            fontSize: '24px',
            textAlign: 'center',
            margin: '0 0 6px 0',
            textTransform: 'uppercase',
          }}>
          Razor Series A
        </p>
        <div style={{ width: '171px' }}>
          <QRCode size={160} style={{ marginLeft: '4px' }} value={name} />
        </div>
        <p
          style={{
            fontWeight: 'bold',
            monospace: true,
            fontSize: '32px',
            textAlign: 'center',
            margin: '6px 0 6px 0',
          }}>
          {name}
        </p>
      </div>
    );
  };

  return (
    <div style={{ margin: '4rem 0 0 4rem' }}>
      {CodeAndName(qrCode)}
      <div className="printOnlyCodes">{CodeAndName(qrCode)}</div>
    </div>
  );
};

PrintableBatteryCode.propTypes = {
  qrCode: PropTypes.string,
};

const BATTERY_30_CELL = '5';
const BATTERY_40_CELL = '3';

const BatteryQRCodes = () => {
  const componentRef = useRef();
  const [name, setName] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [batteryCells, setBatteryCells] = useState(BATTERY_30_CELL);

  useEffect(() => {
    generateQRCode();
  }, [batteryCells, name]);

  const onChange = (event) => {
    const { value } = event.target;
    const truncatedValue = value.length > 4 ? value.substr(0, 4) : value;
    setName(truncatedValue.toUpperCase());
  };

  const onSelectBattery = (event) => {
    const { value } = event.target;
    setBatteryCells(value);
  };

  const onClickReset = (event) => {
    event.preventDefault();
    setName('');
  };

  const generateQRCode = () => {
    if (name.length !== 4) {
      setQrCode('');
      return;
    }
    setQrCode(`${batteryCells}A${name}`);
  };

  return (
    <div className="print_qr_code_container">
      <p>
        Enter the battery name in the field below. Printable QR codes will appear below.{' '}
        <strong>Do not use the browser print function to print the codes!</strong> Press the
        &lsquo;print&rsquo; button for a printer-friendly version of the QR codes.
      </p>
      <form>
        <div className="form_input_section__radio">
          <input
            type="radio"
            id="battery30Cell"
            name="battery_cells"
            value={BATTERY_30_CELL}
            checked={batteryCells === BATTERY_30_CELL}
            onChange={onSelectBattery}
          />
          <label htmlFor="battery40Cell">30-Cell</label>
        </div>
        <div className="form_input_section__radio">
          <input
            type="radio"
            id="battery40Cell"
            name="battery_cells"
            value={BATTERY_40_CELL}
            checked={batteryCells === BATTERY_40_CELL}
            onChange={onSelectBattery}
          />
          <label htmlFor="battery40Cell">40-Cell</label>
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="name">
            Battery Name
          </label>
          <input type="text" name="name" placeholder="Name" value={name} onChange={onChange} />
        </div>
        <div className="form_input_section">
          <button onClick={onClickReset}>Reset</button>
          <ReactToPrint
            trigger={() => (
              <button type="button" disabled={qrCode.length !== 6}>
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </form>
      <div ref={componentRef}>
        {qrCode.length === 6 && <PrintableBatteryCode qrCode={qrCode} />}
      </div>
    </div>
  );
};

export default BatteryQRCodes;
