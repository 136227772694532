import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import './index.scss';

const OkaiQrCode = () => {
  const componentRef = useRef();
  const [names, setNames] = useState('');
  const [qrList, setQrList] = useState([]);

  useEffect(() => {
    let namesArr = names.split(',');
    // Double QR entries to account for bikes having 2 QR's each
    namesArr = namesArr
      .map((name) => name.sanitizeNonAlphaNumerics())
      .filter((name) => name.length === 6)
      .reduce((m, i) => m.concat([i, i]), []);
    setQrList(namesArr);
  }, [names]);

  const onChangeNames = (event) => {
    setNames(event.target.value.toUpperCase());
  };

  const onClickReset = (event) => {
    event.preventDefault();
    setNames('');
    setQrList([]);
  };

  const getReturn = (name, index) => {
    return (
      <div
        key={`key_${index}`}
        style={{
          width: '106px',
          height: '106px',
          borderColor: '#eeeeee',
          borderStyle: 'solid',
          borderWidth: '1px',
          position: 'relative',
          float: 'left',
        }}>
        <div style={{ position: 'absolute', left: '0', top: '0px', height: '20px', width: '100%' }}>
          <span
            style={{
              fontFamily: 'Trebuchet MS',
              monospace: true,
              fontSize: '13px',
              color: 'black',
              fontWeight: 'bold',
              position: 'absolute',
              left: '50%',
              marginLeft: '-32%',
              lineHeight: 1,
            }}>
            razor.mobi
          </span>
        </div>
        <div
          style={{
            width: '75px',
            height: '75px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <QRCode size={75} value={`https://razor.mobi/scan/v1/${name}`} />
        </div>
        <div
          style={{ position: 'absolute', left: '0', bottom: '0', height: '20px', width: '100%' }}>
          <span
            style={{
              fontFamily: 'Trebuchet MS',
              monospace: true,
              fontSize: '11px',
              color: 'black',
              fontWeight: 'bold',
              position: 'absolute',
              bottom: '0',
              left: '50%',
              marginLeft: '-19%',
              lineHeight: 1.3,
            }}>
            {name}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="print_qr_code_container">
      <p>
        Enter 1 or more Okai EB100, ES400, ES400B, or ES600 QR Codes to generate printable QR codes. Seperate multiple names
        with commas.
      </p>
      <form>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="names">
            Vehicle Names (comma separated)
          </label>
          <textarea
            type="textarea"
            name="names"
            placeholder="Names"
            value={names}
            onChange={onChangeNames}
          />
        </div>
        <div className="form_input_section">
          <button onClick={onClickReset}>Reset</button>
          <ReactToPrint
            trigger={() => <button type="button">Print</button>}
            content={() => componentRef.current}
          />
        </div>
      </form>
      <div style={{ width: '636px' }} ref={componentRef}>
        {qrList.map((name, index) => getReturn(name, index))}
      </div>
    </div>
  );
};

export default OkaiQrCode;
